import React from "react";

export const Home = React.lazy(() => import("../pages/Home"));
export const SignIn = React.lazy(() => import("../pages/SignIn"));
export const SignUp = React.lazy(() => import("../pages/SignUp"));
export const ExternalSignUp = React.lazy(() =>
  import("../pages/ExternalSignUp")
);
export const ForgetPw = React.lazy(() => import("../pages/ForgotPw"));
export const Faq = React.lazy(() => import("../pages/Faq"));

// Private
export const Landing = React.lazy(() => import("../pages/Landing"));
export const MyLinks = React.lazy(() => import("../pages/MyLinks"));
export const Stats = React.lazy(() => import("../pages/Stats"));
export const AddNewPodcast = React.lazy(() => import("../pages/AddNewPodcast"));
export const ChooseLink = React.lazy(() => import("../pages/ChooseLink"));
export const Profile = React.lazy(() => import("../pages/profile"));
export const UserConsole = React.lazy(() => import("../pages/UserConsole"));
export const Settings = React.lazy(() => import("../pages/Settings"));
export const Reports = React.lazy(() => import("../pages/Reports"));
export const AddPremiumEpisode = React.lazy(() =>
  import("../pages/AddPremiumContent")
);
export const PremiumEpisodes = React.lazy(() =>
  import("../pages/PremiumEpisodes")
);
export const PremiumPodcasts = React.lazy(() =>
  import("../pages/PremiumPodcasts")
);
export const Episodes = React.lazy(() => import("../pages/PodcastEpisodes"));
export const SubscriptionPlans = React.lazy(() =>
  import("../pages/SubscriptionPlans")
);
